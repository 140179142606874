<template>
    <div class="job-detail-page page-p-t">
        <div class="header">
								<p @click="returnToPre" class="return-btn"><i class="el-icon-arrow-left"></i>返回</p>
                <p class="title">{{  detail.newsTitle }}</p>
                <p class="detail"><span>发布于 {{ detail.createTime }}</span></p>
        </div>
				<el-row :gutter="24">
				<el-col :xs="1" :sm="1" :md="2" :lg="5" :xl="4">
					<div style="width: 100%">&nbsp;</div>
				</el-col>
						  <el-col :xs="22" :sm="22" :md="20" :lg="14" :xl="16">
								<div class="require-content" v-html="detail.newsContent"></div>
							</el-col>
						  <el-col :xs="1" :sm="1" :md="2" :lg="5" :xl="4">
								<div style="width: 100%"> &nbsp;</div>
							</el-col>
					<!-- </div> -->
					
				</el-row>
        
    </div>
</template>
<script>
import { newsDetail } from '@/api/index.js';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
    name: 'JobDetail',
    data() {
        return {
            detail: {}
        }
    },
    created() {
        this.getDetail();
    },
    methods: {
			returnToPre() {
					this.$router.go(-1);
			},
        getDetail() {
            newsDetail(this.$route.query.newsId).then(res => {
                this.detail = res.data.data;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .job-detail-page {
        overflow-y: scroll;

        .header {
            margin: 1.24rem 1.28rem 0.64rem 1.28rem;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-between;
            padding-bottom: 0.64rem;
            border-bottom: 1px solid rgba($color: #81848B, $alpha: 0.6);
						
						.return-btn {
							cursor: pointer;
							font-size: 0.20rem;
							margin-bottom: 0.2rem;
							
						}

            .title {
                @include font-2;
                letter-spacing: 10px;
            }
            .detail {
                margin-top: 0.21rem;
                letter-spacing: 2px;
            }
        }
        .content-box {
						
            margin: 0rem 1.28rem 1.2rem 1.28rem;

            .require-content, .sub-title{
								margin: 0.3rem auto;
                // margin-top: 0.3rem;
                color: rgba($color: #fff, $alpha: 0.8);
                letter-spacing: 3px;
            }

            h3 {
                // @include font-4;
            }

        }
    }
</style>